import React, { Fragment, useState, useEffect, useRef, useContext } from 'react';
import { useCookies } from 'react-cookie';
import Router from 'next/router';
import Link from 'next/link';
import styled, { ThemeContext } from 'styled-components';
import { animated, useSpring } from 'react-spring';
import uuid from 'uuid/v4';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleLogin } from 'react-google-login';
import { formatISO, isValid, parse } from 'date-fns';
import {
  StyleGuide,
  Text,
  Image,
  Icon,
  Input,
  Title,
  RoundedButton,
  Label,
  InputTextWithIcon,
  TextCursive,
} from '../Theme';
import { useCompareWithPrevious, useDarkMode, useMediaQuery } from '../hooks';
import { withTranslation } from '../i18n';
import Tracking from '../tracking';
import { PreCheckLogin } from '../services/webAppEndPoint';
import LanguageComponent from '../LanguageComponent';
import Config from '../config';

const LoginBarContainer = styled.div`
  display: flex;
  width: calc(100% - 100px);
  padding: 20px 50px;
  padding-top: 10px;
  position: absolute;
  top: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 12;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    align-items: stretch;
    padding: 50px 0px 0px 0px;
  }
`;

const HeaderImage = styled.img`
  width: auto;
  max-width: 95%;
  height: 60px;
  margin-top: 20px;
  @media only screen and (max-width: 1280px) {
    height: 40px;
  }
  @media only screen and (max-width: 800px) {
    height: 30px;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 20px;
    height: 40px;
  }
`;

const InputText = styled(InputTextWithIcon)`
  flex: 1;
  width: 200px;
  min-width: 155px;
  @media (max-width: 1440px) {
    width: 150px;
  }
  @media only screen and (max-width: 768px) {
    width: inherit;
  }
`;

const ForgetLink = styled(TextCursive)`
  cursor: pointer;
`;

const NewInput = styled(Input)`
  width: 100%;
  height: 100%;
  cursor: pointer;
  font-weight: bolder;
  background: ${({ theme: { background } }) => background};
  border-radius: ${StyleGuide.borders.roundedButton};
`;

const FixedContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 30px;
  z-index: 11;
`;

const LoginButton = styled.div`
  display: flex;
  font-weight: bolder;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.background};
  color: ${({ theme: { fontOnMain } }) => fontOnMain};
  cursor: pointer;
  padding: 5px 30px;
  ${StyleGuide.boxShadowHigh};
  border-radius: 5px;
  @media only screen and (max-width: 768px) {
    /* background: ${({ theme: { backgroundSecondary } }) => backgroundSecondary}; */
    position: fixed;
    padding: 5px 15px;
    top: 15px;
    right: 15px;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  background: ${({ theme: { backgroundPrimary } }) => backgroundPrimary};
  cursor: pointer;
  padding: 2px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const LoginForm = styled(animated.form)`
  display: flex;
  flex-direction: row;
  position: fixed;
  height: 55px;
  flex: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 2%;
  align-items: center;
  justify-content: space-between;
  background: ${({ background }) => background};
  min-height: 55px;
  ${StyleGuide.boxShadowHigh};

  @media only screen and (max-width: 1024px) {
    position: fixed;
    flex-direction: column;
    padding: 10px 2%;
    height: 70px;
  }
  @media only screen and (max-width: 1280px) {
    font-size: 12px;
  }
  @media only screen and (max-width: 800px) {
    height: 170px;
  }
  @media only screen and (max-width: 768px) {
    font-size: inherit;
    padding: 10px 0px;
    display: block;
  }
`;
const OrText = styled(Text)`
  @media only screen and (max-width: 800px) {
    display: none;
  }
  @media only screen and (max-width: 768px) {
    display: block;
  }
`;

const FacbookContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  min-height: 55px;
  margin: 3px;
  @media only screen and (max-width: 800px) {
    width: 31%;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    position: relative;
    width: 80%;
    align-items: stretch;
  }
`;
const EmailContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 55px;
  margin: 3px;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const LogoInHead = styled(Image)`
  width: 200px;
  @media only screen and (max-width: 1366px) {
    width: 150px;
    margin: 0px;
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 1024px) {
    display: none;
  }
  @media only screen and (max-width: 768px) {
    display: flex;
    flex: 1;
    width: 50%;
    margin: 0 25%;
  }
`;

const LoginBlock = styled.div`
  align-self: stretch;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  border-radius: ${StyleGuide.borders.inputBox};
  flex: 5;
  min-width: 0;
  min-height: 55px;
  background-color: ${({ theme: { backgroundPrimary } }) => backgroundPrimary};
  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
  @media only screen and (max-width: 768px) {
    font-size: inherit;
    padding: 10px 0;
    flex-direction: column;
  }
`;
const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const FbText = styled(Text)`
  white-space: nowrap;
`;

const DimBackground = styled(animated.div)`
  position: fixed;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: 11;
`;

const HeaderFlexContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const LangWrapper = styled.div`
  @media only screen and (max-width: 768px) {
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 11;
  }
`;

const DateFormat = ['DD.MM.YYYY', 'YYYY.MM.DD', 'MM.DD.YYYY'];

/**
 * Animated Page Header for Login
 */
const LandingPageHeader = ({ token, t, status, login, register }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [stayLoggedIn, setStayLoggedIn] = useState(true);
  const theme = useContext(ThemeContext);
  const [, setCookie] = useCookies(['Token', 'StayLogged']);
  const [loginBarVisible, setLoginBarVisible] = useState(false);
  const { transform, opacity } = useSpring({
    transform: `translateY(${loginBarVisible ? '0%' : '-400%'})`,
    opacity: loginBarVisible ? 1 : 0,
  });
  const isSmall = useMediaQuery(['(max-width: 768px)']);
  const prevToken = useRef(token);
  useEffect(() => {
    if (token && !prevToken.current) {
      setCookie('Token', token, {
        expires: stayLoggedIn ? new Date(Date.now() + 14 * 24 * 60 * 60 * 1000) : 0,
      });
      setCookie('StayLogged', true, { expires: new Date(Date.now() + 14 * 24 * 60 * 60 * 1000) });
      Router.push('/main');
    }
    prevToken.current = token;
  }, [token]);

  useCompareWithPrevious(status, (prevStatus) => {
    if (status && prevStatus !== status && prevStatus !== undefined) {
      switch (status) {
        case 7024:
        case 422:
        case 7021:
        case 7040:
        case 7042:
        case 7053:
          Router.push('/login-error');
          break;
        default:
          break;
      }
    }
  });
  return (
    <Fragment>
      {loginBarVisible && (
        <DimBackground
          style={{
            opacity: opacity.interpolate({ range: [0, 0.1, 1], output: [0, 0.5, 1] }),
            zIndex: 12,
          }}
          onClick={() => setLoginBarVisible(false)}
        />
      )}
      <LoginBarContainer>
        <HeaderFlexContainer style={{ justifyContent: isSmall ? 'center' : 'flex-start' }}>
          <HeaderImage src="/static/images/logo-light-2@2x.png" alt="pic" />
        </HeaderFlexContainer>
        <FixedContainer>
          <LangWrapper>
            <LanguageComponent fixed />
          </LangWrapper>
          <LoginButton
            onClick={() => {
              Tracking.track('landingOpenLogin');
              setLoginBarVisible(true);
            }}
          >
            <Text style={{ fontWeight: 'bolder' }} margin={isSmall ? '2px' : '5px'} color="inherit">
              {t('loginEn')}
            </Text>
            {/* {isSmall && <Icon name="dropDownDown" size="8" color="inherit" />} */}
          </LoginButton>
        </FixedContainer>
        <LoginForm
          style={{
            transform,
            opacity: opacity.interpolate({ range: [0, 0.1, 1], output: [0, 1, 1] }),
            zIndex: loginBarVisible ? 12 : 11,
          }}
          background={theme.backgroundPrimary}
          onSubmit={(event) => {
            event.preventDefault();
            if (!email || !password) {
              Router.push('/login-error');
            }
            login({ email, password, origin: 'mail' });
            setLoginBarVisible(false);
            Tracking.track('landingLogin');
          }}
        >
          {isSmall && (
            <CloseButton onClick={() => setLoginBarVisible(false)}>
              <Icon name="decline" size="20" marked />
            </CloseButton>
          )}
          <LogoInHead src="/static/images/logo-light@2x.png" />
          <LoginBlock>
            <FacbookContainer
              onClick={() => {
                Tracking.track('landingFBLogin');
              }}
            >
              <FacebookLogin
                isMobile={false}
                appId={Config.fbAppID}
                onFailure={() => Router.push('/login-error')}
                version="3.3"
                scope="public_profile,email,user_birthday,user_location,user_gender"
                fields="email,first_name,last_name, name,birthday,gender,location.fields(location),picture.width(1080).height(1920)"
                callback={async ({
                  accessToken,
                  id,
                  email: fbMail,
                  gender,
                  location,
                  first_name: name,
                  picture,
                  birthday,
                }) => {
                  let alreadyExist = false;

                  await PreCheckLogin(fbMail?.trim())
                    .then((userExisting) => {
                      if (userExisting) alreadyExist = true;
                    })
                    .catch((error) => {
                      console.log('err', error);
                    });

                  await PreCheckLogin(id)
                    .then((userExisting) => {
                      if (userExisting) alreadyExist = true;
                    })
                    .catch((error) => {
                      console.log('err', error);
                    });

                  if (alreadyExist) {
                    login({ token: accessToken, userId: id, origin: 'facebook' });
                  } else if (
                    accessToken &&
                    id &&
                    gender &&
                    fbMail &&
                    isValid(parse(birthday, 'MM/dd/yyyy', new Date())) &&
                    picture &&
                    location &&
                    name
                  ) {
                    const llocation = location?.location;
                    register({
                      token: accessToken,
                      userId: id,
                      email: fbMail,
                      gender: gender === 'female' ? 'female' : 'male',
                      birthday: formatISO(new Date(birthday)),
                      name,
                      location: {
                        latitude: llocation?.latitude,
                        longitude: llocation?.longitude,
                        city: llocation?.city,
                        country: llocation?.country,
                      },
                      picture: { uri: picture?.data?.url, mimeType: 'image/jpeg', id: uuid() },
                      origin: 'facebook',
                      previewPath: picture?.data?.url,
                    });
                  } else {
                    Router.push({
                      pathname: '/auth-register',
                      query: {
                        token: accessToken,
                        userId: id,
                        email: fbMail,
                        name,
                        origin: 'facebook',
                        picture:
                          picture?.data?.is_silhouette ?? true
                            ? null
                            : JSON.stringify({
                                uri: picture.data.url,
                                mimeType: 'image/jpeg',
                                id: uuid(),
                              }),
                        gender: gender === 'female' ? 'female' : 'male',
                      },
                    });
                  }
                }}
                render={(renderProps) => (
                  <RoundedButton
                    backgroundColor={StyleGuide.colors.facebook}
                    flex={1}
                    padding="3px 20px"
                    margin="10px"
                    style={{ overflow: 'hidden' }}
                    onClick={() => {
                      renderProps.onClick();
                    }}
                  >
                    <Icon name="facebook" color={StyleGuide.colors.fontOnPrimary} size={25} />
                    <FbText style={{ fontWeight: 'bolder' }}>{t('loginEn')}</FbText>
                  </RoundedButton>
                )}
              />
              <GoogleLogin
                clientId={Config.googleClientID}
                redirectUri={Config.urls.web}
                render={(renderProps) => (
                  <RoundedButton
                    backgroundColor={StyleGuide.colors.google}
                    flex={1}
                    padding="3px 20px"
                    margin="10px"
                    style={{ overflow: 'hidden' }}
                    onClick={() => {
                      renderProps.onClick();
                    }}
                  >
                    <Icon name="google" color={StyleGuide.colors.fontOnPrimary} size={25} />
                    <FbText style={{ fontWeight: 'bolder' }}>{t('loginEn')}</FbText>
                  </RoundedButton>
                )}
                responseType="id_token"
                onSuccess={({ tokenId, googleId }) =>
                  login({ token: tokenId, userId: googleId, origin: 'google' })
                }
                onFailure={(response) => console.log(response, 'GOOGLE FAIL')}
              />

              <OrText color={theme.fontOnPrimary} margin="0.8em">
                {t('or')}
              </OrText>
            </FacbookContainer>
            <EmailContainer>
              <InputText
                stretch
                style={{ overflow: 'hidden' }}
                value={email}
                placeholder={t('common:mail')}
                onChange={({ target: { value: val } }) => setEmail(val.trim())}
                iconName="post"
              />
              <InputText
                stretch
                style={{ overflow: 'hidden' }}
                type="password"
                value={password}
                placeholder={t('password')}
                onChange={({ target: { value: val } }) => setPassword(val)}
                iconName="password"
              />
              <TextDiv>
                <Label
                  style={{ fontSize: '0.8em', color: theme.fontOnPrimary }}
                  onClick={() => Tracking.track('landingStayLogged')}
                >
                  <Input
                    type="checkbox"
                    checked={stayLoggedIn}
                    onChange={() => setStayLoggedIn(!stayLoggedIn)}
                    margin="0px 5px"
                  />
                  {t('stayLoggedIn')}
                </Label>
                <Link href={{ pathname: '/password-reset', query: { email: email || undefined } }}>
                  <ForgetLink
                    style={{ fontSize: '0.8em' }}
                    color={theme.fontOnPrimary}
                    textAlign="right"
                  >
                    {`${t('forgotPassword')}`}
                  </ForgetLink>
                </Link>
              </TextDiv>
              <RoundedButton
                backgroundColor={StyleGuide.colors.primary}
                flex="unset"
                width="100px"
                margin="10px"
              >
                <NewInput
                  style={{ fontWeight: 'bolder' }}
                  type="submit"
                  value="Login"
                  color={StyleGuide.colors.fontOnPrimary}
                />
              </RoundedButton>
            </EmailContainer>
          </LoginBlock>
        </LoginForm>
      </LoginBarContainer>
    </Fragment>
  );
};
export default withTranslation(['login', 'common'])(LandingPageHeader);
