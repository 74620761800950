import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import { animated, useSpring } from 'react-spring';
import styled, { ThemeContext } from 'styled-components';
import { useRouter } from 'next/router';
import { useCookies } from 'react-cookie';
import { useTranslation, i18n } from '../i18n';
import { StyleGuide, Icon, Text } from '../Theme';
import { useMediaQuery } from '../hooks';
import UseOutsideClick from '../Theme/UseOutsideClick';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: ${({ isText }) => (isText ? '5px' : '50%')};
  background: transparent;
  padding: ${({ isText }) => (isText ? '10px 15px' : '0px')};
  margin-right: 10px;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    margin: ${({ isStatic }) => (isStatic ? '0px' : '5px')};
  }
`;

const IconCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 2px;
  background: ${({ theme: { background } }) => background};
  color: white;
  @media only screen and (max-width: 768px) {
    padding: 2px;
    border-radius: 5px;
  }
`;

const TextContainer = styled(animated.div)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  position: absolute;
  top: 50px;
  width: 200px;
  min-height: 130px;
  height: min-content;
  top: 0;
  border-radius: 10px;
  background: ${({ theme: { backgroundSecondary } }) => backgroundSecondary};
  ${StyleGuide.boxShadowHigh};
  overflow: hidden;
  cursor: initial;
  z-index: 10;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  padding: 10px 15px;
  background: ${({ theme: { background } }) => background};
  color: ${StyleGuide.colors.fontOnBackground};
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  align-items: stretch;
  max-height: 250px;
  overflow: auto;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    width: 0px;
  }
`;

const ItemContainer = styled(animated.div)`
  display: flex;
  justify-content: flex-start;
  position: relative;
  align-items: center;
  padding: 7px 15px;
  cursor: pointer;
`;

const Splitter = styled.div`
  display: flex;
  margin: 0px 15px;
  background: ${({ theme: { splitter } }) => splitter};
  height: 1px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme: { fontOnMain } }) => fontOnMain};
  padding: 2px;
  border-bottom: 2px solid white;
`;

const ItemComponent = ({ text, active, onPress }) => {
  const theme = useContext(ThemeContext);
  const [hover, setHover] = useState(false);
  const props = useSpring({
    to: {
      background: hover || active ? theme.background : theme.noBackgroundSecondary,
      color: hover || active ? StyleGuide.colors.fontOnBackground : theme.fontOnPrimary,
    },
    config: { duration: 150 },
  });
  return (
    <ItemContainer
      onClick={onPress}
      style={props}
      active={active}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Text style={{ textAlign: 'left', color: 'inherit', fontSize: '0.8em', margin: '5px' }}>
        {text}
      </Text>
    </ItemContainer>
  );
};

const langs = [
  {
    key: 'de',
    value: 'Deutsch',
  },
  {
    key: 'en',
    value: 'English',
  },
  {
    key: 'da',
    value: 'Dansk',
  },
  {
    key: 'pt',
    value: 'Português',
  },
  {
    key: 'es',
    value: 'Español',
  },
  {
    key: 'nb',
    value: 'Norsk',
  },
  {
    key: 'fi',
    value: 'Suomi',
  },
];

const shortLangs = [
  {
    key: 'de',
    value: 'Deutsch',
  },
  {
    key: 'en',
    value: 'English',
  },
];

const denglishPaths = ['/agb', '/datenschutz', '/impressum', '/newsletter/unsubscribed'];

const getLangKey = (lang) => {
  if (!lang) return 'en';
  switch (lang.slice(0, 2)) {
    case 'de':
      return 'de';
    case 'da':
      return 'da';
    case 'es':
      return 'es';
    case 'pt':
      return 'pt';
    case 'fi':
      return 'fi';
    case 'no':
    case 'nb':
    case 'nn':
      return 'nb';
    case 'fi':
      return 'fi';
    default:
      return 'en';
  }
};

/**
 * Component displaying further infos of profile
 */
const LanguageComponent = ({ fixed = false, config, isStatic = false }) => {
  const router = useRouter();
  const { t } = useTranslation(['settings', 'values']);
  const [open, setOpen] = useState(false);
  const isSmall = useMediaQuery(['(max-width: 768px)']);
  const [isAnimating, setIsAnimating] = useState(false);
  const outsideRef = useRef();
  const [cookies, ,] = useCookies(['fromApp']);

  UseOutsideClick(outsideRef, () => {
    if (open) {
      setOpen(false);
    }
  });

  useEffect(() => {
    setIsAnimating(true);
  }, [open]);

  const langSelects = useMemo(
    () => (denglishPaths.includes(router.pathname) ? shortLangs : langs),
    [router]
  );

  const props = useSpring({
    to: {
      opacity: open ? 1 : 0,
      transform: open
        ? `translateY(${isSmall ? '-75%' : '0%'}) translateX(0%) scale(1) `
        : `translateY(${isSmall ? '-75%' : '-50%'}) translateX(50%) scale(0)`,
      right: open ? '50px' : '0px',
    },

    onRest: () => setIsAnimating(false),
  });

  const fixedProps = useSpring({
    to: {
      opacity: open ? 1 : 0,
      transform: open ? `translate(60%,40px) scale(1) ` : `translate(50%,-30px) scale(0)`,
      right: '20px',
    },

    onRest: () => setIsAnimating(false),
  });

  return (
    <Container isText={fixed && !isSmall} isStatic={isStatic}>
      {fixed ? (
        <Wrapper onClick={() => setOpen(!open)}>
          <Text
            style={{
              fontSize: '1.1em',
              margin: '2px',
              fontWeight: 'bold',
            }}
            color="inherit"
          >
            {langs.find((item) => item.key === getLangKey(i18n.language))?.value || 'English'}
          </Text>
          <Icon name="dropDownDown" size="6" />
        </Wrapper>
      ) : (
        <IconCircle onClick={() => setOpen(!open)} marked={open}>
          <Icon name="globe" color="inherit" size={25} />
        </IconCircle>
      )}
      {(open || isAnimating) && (
        <TextContainer style={fixed ? fixedProps : props} ref={outsideRef}>
          <TitleContainer>
            <Text style={{ fontSize: '0.8em', margin: '2px' }}>{t('chooseLang')}</Text>
          </TitleContainer>
          <ItemsContainer>
            {langSelects?.map((item, index) => (
              <div>
                <ItemComponent
                  onPress={() => {
                    i18n.changeLanguage(item?.key);
                    setOpen(false);
                  }}
                  text={item?.value}
                  active={i18n?.language === item?.key}
                />
                {index < langSelects.length - 1 && <Splitter />}
              </div>
            ))}
          </ItemsContainer>
        </TextContainer>
      )}
    </Container>
  );
};
export default LanguageComponent;
