import React, { useEffect, useRef, useState, useCallback, Fragment } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { animated, useSpring } from 'react-spring';
import { useCookies } from 'react-cookie';
import CookieConsent from 'react-cookie-consent';
import { useRouter } from 'next/router';
import Config from '../config';
import { withTranslation, i18n } from '../i18n';
import { useDarkMode, useMeasure } from '../hooks';
import ThemeProfiles from '../Theme/ThemeProfiles';
import { Background, Icon, Text, StyleGuide, DocumentTitle } from '../Theme';
import LandingPageHeader from './LandingPageHeader';
import Register from './Register';
import LoadingScreen from '../Loading/LoadingScreen';
import LandingBoxesList from '../LandingBoxes';
import LandingPageFooter from '../Footer/LandingPageFooter';
import LandingStatistics from './LandingStatistics';

const ContentDiv = styled.div`
  display: flex;
  height: 100vh;
  min-height: 600px;
  align-items: center;
  padding: 0px 200px 0px 5%;
  background-color: darkgrey;
  background-image: url('/static/images/bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  @media only screen and (max-width: 768px) {
    padding: 30px 30px 0px 30px;
  }
`;

const CookieText = styled.p`
  text-align: center;
  font-size: 15px;
  color: white;
`;

const CookieLink = styled.a`
  color: white;
  transition: 0.3s;
  :hover {
    opacity: 0.8;
  }
`;

const DiagonalBox = styled.div`
  position: absolute;
  top: -75px;
  width: 100%;
  background: ${({ theme: { background } }) => background};
  height: 150px;
  /* z-index: 20; */
  transform: skewY(-3deg);
`;

const Wrapper = styled.div`
  display: flex;
  padding: 20px 5%;
  background: ${({ theme: { backgroundPrimary } }) => backgroundPrimary};
  margin-top: 100px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
  @media only screen and (max-width: 1440px) {
    padding: 20px 2%;
  }
  @media only screen and (max-width: 768px) {
    padding-bottom: 0px;
  }
`;

/**
 * Landing Page Component with Login and Register
 */
const LandingPage = ({ t, isLoading, logout, token, ...props }) => {
  const [cookies, , removeCookie] = useCookies(['Token', 'StayLogged', 'fromApp']);
  const darkModeActive = useDarkMode();
  const prevToken = useRef(token);

  const router = useRouter();

  useEffect(() => {
    const isFramed = () => {
      if (typeof window === 'undefined') return false;
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    };
    if (isFramed()) window.open(window.location.href, '_top');
  }, []);

  useEffect(() => {
    if (token && prevToken.current) {
      if (cookies.StayLogged) {
        router.push('/main');
      } else {
        logout();
        removeCookie('Token', { path: '/' });
      }
    }
    prevToken.current = token;
  }, [logout, token]);

  return (
    <>
      <ThemeProvider theme={darkModeActive ? ThemeProfiles.themeDark : ThemeProfiles.themeLight}>
        <Background>
          <DocumentTitle text={Config.appName} />

          {isLoading && <LoadingScreen />}
          {!cookies?.fromApp && (
            <CookieConsent
              buttonStyle={{ color: '#000', background: '#fff', borderRadius: '20px' }}
              buttonText={t('landing:accept')}
            >
              <CookieText>
                {t('landing:cookieUse')}
                <CookieLink href={Config.urls.privacy}>{t('moreInfo')}</CookieLink>
              </CookieText>
            </CookieConsent>
          )}
          <LandingPageHeader {...props} token={token} />
          <ContentDiv>
            <Register {...props} />
          </ContentDiv>
          <Wrapper>
            <LandingBoxesList />
            <LandingStatistics />
          </Wrapper>
          <LandingPageFooter style={{ zIndex: 11 }} />
        </Background>
      </ThemeProvider>
    </>
  );
};
export default withTranslation(['landing', 'login', 'common'])(LandingPage);
